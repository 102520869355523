.menu{
  background: var(--verde);
  padding:12px 0px;
  border-bottom:2px solid #7faa7f;
}
.gridMenu{
  display: grid;
  grid-template-columns: 300px 1fr;
}
.linksMenu{
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
}
.linksMenu a{
  display: block;
  padding: 10px 25px;
  color:#fff;
  cursor: pointer;
  transition: all .3s;
  border-radius: 25px;
  text-decoration: none;
}
.linksMenu a:hover{
  background: #fff;
  color:var(--verdeDeschis);
}
.logo{
  display: flex;
  align-items: center;
}
.logo img{
  max-width:100%;
  max-height: 65px;
}
@media only screen and (max-width: 900px) {
  .linksMenu{
    display: none;
  }
}