.bannerSus{
  background: var(--verde);
  padding:65px 0px 45px;
  background-image: url('../../assets/sports-tools.jpg');
  background-size: 100%;
  background-repeat: no-repeat;
}
.gridCtn{
  display: grid;
  grid-template-columns: 1fr 1.5fr;
  grid-gap:10px;
}
.infoLeft{
  display: flex;
  flex-direction: column;
  justify-content: center;
}
.imgTel{
  text-align: center;
}
.imgTel img{
  max-height:600px;
}
.titluMare{
  font-size: 35px;
  color: #fff;
  margin-bottom: 20px;
}
.titluMare span{
  font-weight:500;
}
.titluMare span span{
  color:var(--portocaliu);
}
.textLeft{
  font-size:18px;
  color:#fff;
  margin-bottom: 40px;
}
.textLeft p{
  margin-bottom:8px;
}
.butoaneDownload{
  display: grid;
  grid-template-columns: 1fr 1fr;
  grid-gap: 15px;
}
@media only screen and (max-width: 1500px) {
    .bannerSus{
        background-size: cover;
    }
}
@media only screen and (max-width: 1200px) {
    .gridCtn{
        grid-template-columns: 1fr 1fr;
    }
}
@media only screen and (max-width: 1050px) {
    .gridCtn{
        grid-template-columns: 1fr;
        grid-gap: 70px;
    }
}
@media only screen and (max-width: 800px) {
    .titluMare{
        font-size: 28px;
    }
}
@media only screen and (max-width: 550px) {
    .imgTel img{
      max-width:100%;
    }
}
@media only screen and (max-width: 500px) {
    .butoaneDownload{
      grid-template-columns: 1fr;
    }
}
@media only screen and (max-width: 450px) {
    .bannerSus{
      padding: 50px 0px 30px;
    }
    .titluMare{
      font-size: 22px;
    }
    .textLeft{
      font-size: 16px;
    }
}