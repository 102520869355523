.ctnMare{
  padding:40px 0px 30px;
  background: var(--portocaliu);
  position: absolute;
  left:0;
  bottom:0;
  width:100%;
}
.ctnGrid{
  display: grid;
  grid-template-columns: 1fr 1.5fr;
}
.copyright{
  color:#f2f2f2;
  font-size: 14px;
}
.linkFooter{
  display: flex;
  justify-content: space-around;
  align-items: flex-end;
}
.linkFooter a{
  color:#fff;
  cursor: pointer;
  transition: all .3s;
  text-decoration: none;
}
.linkFooter a:hover{
  opacity:0.7;
}
.copyright a{
  color:var(--verde);
  font-weight:600;
  cursor: pointer;
}
@media only screen and (max-width: 750px) {
  .copyright{
    grid-area: copyright;
    text-align: center;
    font-size:12px;
    margin-top: 6px;
    padding-top: 15px;
    border-top: 1px solid #fff;
  }
  .linkFooter{
    grid-area: linkFooter;
  }
  .ctnGrid{
    grid-template-columns: 1fr;
    grid-template-areas: "linkFooter" "copyright";
    grid-gap:15px;
  }
  .ctnMare{
    padding: 30px 0px 15px;
  }
}
@media only screen and (max-width: 500px) {
    .linkFooter{
      align-items: center;
      flex-direction: column;
      gap: 10px;
    }
}