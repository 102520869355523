.ctnMare{
  background: #f2f2f2;
  padding: 1px 0px;
}
.ctnCont{
  position: relative;
  margin: 65px 0px;
display: block;
}
.img{
  width:65%;
  display: inline-block;
}
.img img{
  width:100%;
}
.info{
  position: absolute;
  background: #fff;
  top: 13%;
  width: 45%;
  padding: 35px;
  box-sizing: border-box;
  height: 74%;
  display: flex;
  flex-direction: column;
  justify-content: center;
}
.contLeft .info{
  right:0;
  -webkit-box-shadow: 11px 11px 18px -1px rgba(160, 160, 160, 0.36);
  -moz-box-shadow: 11px 11px 18px -1px rgba(160, 160, 160, 0.36);
  box-shadow: 11px 11px 18px -1px rgba(160, 160, 160, 0.36);
}
.contRight{
  text-align: right;
}
.contRight .info{
  left:0;
  -webkit-box-shadow: -11px 11px 18px -1px rgba(160, 160, 160, 0.36);
  -moz-box-shadow: -11px 11px 18px -1px rgba(160, 160, 160, 0.36);
  box-shadow: -11px 11px 18px -1px rgba(160, 160, 160, 0.36);
  text-align: left;
}
.titlu{
  font-size:28px;
  margin-bottom: 30px;
  color: #2a2a2a;
}
.text{
  font-size: 17px;
  color: #2a2a2a;
}
.text p{
  margin:10px 0px;
}
@media only screen and (max-width: 1100px) {
    .img{
        width: 55%;
    }
    .info{
      width: 62%;
      top: 10%;
      height: 80%;
    }
}
@media only screen and (max-width: 950px) {
    .img{
      width:100%;
    }
    .info{
      width:100%;
      height: 70%;
      top: 15%;
      background: rgba(255, 255, 255, 0.75);
      box-sizing: border-box;
    }
}
@media only screen and (max-width: 550px) {
    .info{
      height: 100%;
      top: 0;
    }
    .ctnCont{
      margin: 20px 0px;
    }
    .img img{
      display: block;
    }
    .img{
      display: block;
    }
}
@media only screen and (max-width: 500px) {
    .titlu{
      font-size: 20px;
      margin-bottom: 2px;
    }
    .text{
      font-size: 14px;
    }
}
@media only screen and (max-width: 400px) {
  .text p:nth-child(2){
    display: none;
  }
}