label{
    display: block;
    font-size:12px;
    font-weight:600;
    color:#2a2a2a;
    margin:10px 0px 6px;
}
input, textarea{
    border:1px solid #ccc;
    padding: 5px 10px 4px;
    outline:none;
    width:100%;
    max-width:100%;
    box-sizing: border-box;
}
.btnSubmit{
    margin-top:10px;
    border:none;
    outline:none;
    background:#005c2b;
    color:#fff;
    padding:6px 12px 5px;
    font-size:15px;
    cursor: pointer;
    transition: all .3s;
}
.btnSubmit:hover{
    background:#2a2a2a;
}