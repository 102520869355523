.ctnStatic{
    margin:35px 0px 25px;
    color: #2a2a2a;
}
.ctnStatic div, .ctnStatic p{
    margin-top:10px;
}
.ctnStatic h1, .ctnStatic h2, .ctnStatic h3, .ctnStatic h4{
    margin-top:15px;
    margin-bottom:10px;
}
.ctnStatic h1{
    font-size:22px;
    font-weight:600;
}
.ctnStatic h2{
    font-size:20px;
    font-weight:600;
}
.ctnStatic h3{
    font-size:18px;
    font-weight:600;
}
.ctnStatic h4{
    font-weight:600;
}