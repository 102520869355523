.ctnMare{
    margin:35px 0px 25px;
}
.titluMare{
    margin-bottom:20px;
    font-weight:600;
    font-size:26px;
    color:#2a2a2a;
}
.ctnGrid{
    display: grid;
    grid-template-columns: 1fr 1fr;
    grid-gap:15px;
}
@media only screen and (max-width: 650px) {
    .ctnGrid{
        grid-template-columns: 1fr;
    }
}