.gridCtn{
  display: grid;
  grid-template-columns: 1fr 1fr;
  grid-gap:35px;
  position: relative;
}
.beneficiu{
  -webkit-box-shadow: 2px 4px 14px -3px rgba(0,0,0,0.41);
  -moz-box-shadow: 2px 4px 14px -3px rgba(0,0,0,0.41);
  box-shadow: 2px 4px 14px -3px rgba(0,0,0,0.41);
  border-radius: 65px;
  padding:60px 115px;
  box-sizing: border-box;
}
.titlu{
  font-size:24px;
  color:#2a2a2a;
  margin-bottom:20px;
}
.info{
  color:#2a2a2a;
}
.info p{
  margin-top:12px;
}
.img{
  position: absolute;
  left: 50%;
  top: 50%;
  transform: translate(-50%,-50%);
}
.img img{
  max-height:355px;
}
.ctnMare{
  padding:45px 0px;
}
.verde{
  box-shadow: none;
  background: var(--verdeDeschis);
}
.portocaliu{
  box-shadow: none;
  background: var(--portocaliu);
}
.txtAlb .titlu, .txtAlb .info{
  color:#fff;
}
.titluMare{
  font-size:35px;
  margin-bottom:35px;
  color: #2a2a2a;
}
.txtBeneficii{
  margin-bottom:45px;
  color:#2a2a2a;
}
.txtBeneficii p{
  margin:15px 0px;
}
@media only screen and (max-width: 900px) {
    .gridCtn{
      grid-template-columns: 1fr;
      grid-gap: 8px;
    }
    .img{
      display: none;
    }
}
@media only screen and (max-width: 800px) {
    .titluMare{
        font-size: 28px;
    }
}
@media only screen and (max-width: 700px) {
    .beneficiu{
      padding: 50px;
    }
}
@media only screen and (max-width: 500px) {
    .txtBeneficii{
      margin-bottom: 15px;
    }
    .beneficiu{
      padding: 32px;
      border-radius: 35px;
    }
    .titlu{
      font-size: 20px;
    }
}
@media only screen and (max-width: 450px) {
    .titluMare{
      font-size: 24px;
    }
}