.google{
  display: grid;
  grid-template-columns: 30px 1fr;
  grid-gap:20px;
  padding: 12px 25px;
  box-sizing: border-box;
  background: #fff;
  border-radius: 40px;
  cursor: pointer;
  transition: all .3s;
}
.txtGoogle{
  font-size: 19px;
  font-weight: 600;
  color: #2a2a2a;
}
.txtGoogle p{
  font-size: 14px;
  font-weight: normal;
  text-transform: uppercase;
  margin-bottom: 2px;
}
.google:hover{
  -webkit-box-shadow: 4px 4px 5px 2px rgba(0,0,0,0.4);
  -moz-box-shadow: 4px 4px 5px 2px rgba(0,0,0,0.4);
  box-shadow: 4px 4px 5px 2px rgba(0,0,0,0.4);
}
.google img{
  max-width:100%;
  max-height:65px;
}
@media only screen and (max-width: 1050px){
  .google{
      text-align: center;
    }
}