.ctnMare{
  background: var(--verde);
  padding:65px 0px;
}
.gridCtn{
  display: grid;
  grid-template-columns: 1.2fr 1fr;
  grid-gap:45px;
}
.infoLeft img{
  max-width: 100%;
}
.infoRight{
  display: flex;
  flex-direction: column;
  justify-content: center;
}
.titlu{
  color:#fff;
  font-size:28px;
  margin-bottom:25px;
}
.text{
  color:#fff;
  font-size:18px;
}
.butoaneDownload{
  margin-top:35px;
  display: grid;
  grid-template-columns: 1fr 1fr;
  grid-gap:20px;
}

@media only screen and (max-width: 1200px) {
    .gridCtn{
        grid-template-columns: 1fr 1fr;
    }
}
@media only screen and (max-width: 1050px){
  .gridCtn{
        grid-template-columns: 1fr;
    }
    .infoLeft img{
      max-width:50%;
    }
    .infoLeft{
      text-align: center;
    }
}
@media only screen and (max-width: 800px) {
  .titlu{
    font-size: 24px;
  }
}
@media only screen and (max-width: 550px) {
  .ctnMare{
    padding: 35px 0px;
  }
}
@media only screen and (max-width: 500px) {
    .butoaneDownload{
      grid-template-columns: 1fr;
      grid-gap: 15px
    }
}
@media only screen and (max-width: 450px) {
    .text{
      font-size: 16px;
    }
}
@media only screen and (max-width: 400px) {
    .titlu{
      font-size:20px;
    }
}